import React from 'react';
import './VideoBackground.css';

const VIDEO_WIDTH = 1920;
const VIDEO_HEIGHT = 1080;

const videoID = '8gTUNpaBOP0';
const videoUrl = `https://www.youtube.com/embed/${videoID}?autoplay=1&controls=0&mute=1&loop=1&modestbranding=1&showinfo=0&enablejsapi=1&&widgetid=3&playlist=${videoID}&rel=0`;

function VideoBackground() {
  return (
    <div className="video-background">
        <iframe
            width={VIDEO_WIDTH}
            height={VIDEO_HEIGHT}
            src={videoUrl}
            title="QuizBin"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
    </div>
  );
}

export default VideoBackground;
