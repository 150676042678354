import React  from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import './App.css';
import WebsiteOverlay from './WebsiteOverlay';
import VideoBackground from './VideoBackground';

const App = () => (
  <ReactFullpage
    licenseKey = {'GPL'}
    scrollingSpeed = {1000}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <div className="section">
             <div className="App flex relative w-full h-full">
                <WebsiteOverlay />
                <VideoBackground />
            </div>
          </div>
          <div className="section">
            <div className="App flex relative w-full h-full">
              <WebsiteOverlay />
              <p>Section 2 dfsdfsdrf</p>
            </div>
          </div>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default App;
